<template>
  <div class="main-container-LBG">
    <loginBG></loginBG>
    <div class="registerMain">
      <div class="login-container">
        <div class="login-title-style">注册</div>
        <el-form class="formClass" label-position="left" label-width="80px" :model="ruleForm" :rules="rules"
                 ref="ruleForm">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="ruleForm.username" size="small" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="ruleForm.password" size="small" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input type="password" v-model="ruleForm.confirmPassword" size="small" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="验证码" prop="captcha">
            <el-input v-model="ruleForm.captcha" id="captcha_" style="width: 140px;position: relative;top: -10px"
                      size="small" autocomplete="off"></el-input>
            <img :src="checkCode" @click="getCheckCode" width="100" height="32"
                 style="margin-left: 10px;">
          </el-form-item>

          <button type="button" class="reg-button-style" @click="register">
            <span class="login-word-style">提交</span>
          </button>
          <div class="tips">已有账号？去<span class="blue cursorPoint">登录</span></div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import loginBG from "@/components/loginBG";
import {randomUUID} from "@/utils/util";

export default {
  name: "Register",
  components: {
    loginBG
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.trim().length == 0) {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      checkCode:undefined,
      text: '发送验证码',
      ruleForm: {
        password: undefined,
        confirmPassword: undefined,
        checkKey: undefined,
        captcha: undefined,
        username: undefined,
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 255, message: '长度在 3 到 255 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 3, max: 255, message: '长度在 3 到 255 个字符', trigger: 'blur'}
        ],
        captcha: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '长度在4 个字符', trigger: 'blur'}
        ],
        confirmPassword: [
          {validator: validatePass, trigger: 'blur',required: true}
        ],
      }
    }
  },
  methods: {
    getCheckCode() {
      this.ruleForm.checkKey = randomUUID()
      this.axios.get('/jeecg-boot/sys/randomImage/' + this.ruleForm.checkKey).then((res) => {
        this.checkCode = res.result;
      })
    },
    // 注册
    register() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.axios.post('/jeecg-boot/user/register', this.ruleForm).then(res => {
            if(res.success){
              this.$message.success('注册成功')
              setTimeout(()=>{
                this.$router.push('/')
              },1500)
            }else {
              this.$message.error(res.message)
            }
          })
        }
      })


    }
  },
  mounted() {
    this.getCheckCode()
  }
}
</script>

<style scoped>
.main-container-LBG {
  width: 100%;
}

.registerMain {
  width: 100%;
  background: #f1f1f1;
  padding-top: 15px;
}

.main-container-LBG {
  margin: 0 auto;
}

.login-title-style {
  width: 100px;
  font-size: 24px;
  font-weight: bold;
  color: #089BAB;
  margin: 0 auto;
  border-bottom: 4px solid #089BAB;
  padding-bottom: 15px;
}

.login-container {
  width: 1000px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 0px auto 20px;
  padding-bottom: 30px;
  clear: both;

}

.formClass {
  width: 60%;
  margin: 30px auto;
}

.formClass .el-form-item {
  margin-bottom: 20px;
}

.reg-button-style {
  width: 350px;
  height: 32px;
  background: #089BAB;
  border-radius: 6px;
  display: block;
  margin: 60px auto 0;
  border: none;
  box-shadow: none;
}

.tips {
  font-size: 12px;
  color: #888;
  margin-top: 20px;
  text-align: center;
}

.cursorPoint {
  cursor: pointer;
}

.blue {
  color: #3a8ee6;
}
</style>
